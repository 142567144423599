import React from "react";
import styled from "styled-components";
import side_post from "../../images/기업분석 보고서 와이드 배너.png"

function Side(){
    return(
        <CustomSide>
            {/*<img src={side_post} style={{width: "40%"}}/>*/}
            {/*<script async*/}
            {/*        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2633928464528168"*/}
            {/*        crossOrigin="anonymous"></script>*/}
            {/*<ins className="adsbygoogle"*/}
            {/*     style={{display: "block"}}*/}
            {/*     data-ad-client="ca-pub-2633928464528168"*/}
            {/*     data-ad-slot="3259881639"*/}
            {/*     data-ad-format="auto"*/}
            {/*     data-full-width-responsive="true"></ins>*/}
            {/*<script>*/}
            {/*    (adsbygoogle = window.adsbygoogle || []).push({});*/}
            {/*</script>*/}
        </CustomSide>
    )
}

export default Side;

const CustomSide = styled.div`
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media ${({theme}) => theme.device.tabletL} {
        display: none;
    }
`;