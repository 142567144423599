import React, {useEffect, useState} from "react";
import axios from 'axios';
import styled from "styled-components";
import Footer from "../../components/Footer/Footer"
import MenuBar from "../../components/Header/MenuBar";
import Table from "../../components/Table/Table";
import Pagination from "react-js-pagination";
import "../../components/style/Pagination.css"
import Side from "../../components/Side/Side";
import {Helmet} from "react-helmet";

function FindIPC(){
    // table 변수
    const [tableDataList , setTableDataList] = useState([]);
    const tableHeader = ["IPC 코드", "설명"];
    // search 변수
    const [searchTarget, setSearchTarget] = useState("");
    // pagination 변수
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [dataLength, setDataLength] = useState();
    // 코드 종류
    const [codeNumber, setCodeNumber] = useState();

    //ipc code list
    const ipcList = () =>{
        axios.post("/api/ipc/ipcList",{page:page, limit:limit, searchTarget:searchTarget})
            .then((response)=>{
                // console.log(response.data);
                setCodeNumber(response.data.whatIsThisCode);
                setDataLength(response.data.totalCount)
                const changeData = response.data.data.map( item => ({
                    code: item.ipc_code,
                    def_kr: item.def_kr
                }))
                setTableDataList(changeData)
            })
            .catch((error)=>{
                console.error("Error cpcList axios request", error)
            })
    }
    useEffect(()=>{
        ipcList()
    },[page])

    // search filtering
    const filterData = (e) => {
        setSearchTarget(e.target.value)
    }

    //pagination
    const handlePageChange = (page) => {
        setPage(page)
    }

    //keypress event
    const keyPress = (e) => {
        if(e.key === 'Enter'){
            setPage(1)
            ipcList()
        }
    }
    return(
        <>
            <Helmet>
                <title>IPC 검색</title>
                <meta name="description" content="IPC(국제특허분류코드)를 검색하여 조회할 수 있으며 검색한 IPC(국제특허분류코드)의 상하위 기술코드를 확인할 수 있습니다." />
                <meta name="robots" content="index, follow, max-snippet:-1" />
                <meta property="og:type" content="article" />
                <meta name="og:title" content="IPC 검색" />
                <meta property="og:description" content="IPC(국제특허분류코드)를 검색하여 조회할 수 있으며 검색한 IPC(국제특허분류코드)의 상하위 기술코드를 확인할 수 있습니다." />
                <meta name="og:site_name" content="search code" />
                <meta name="og:url" content="https://code.researchall.net/searchIPC" />
            </Helmet>
            <MenuBar />
            <div style={{display:"flex", justifyContent:"center"}}>
                <Side />
                <Center>
                    <TitleField>
                        <Title>IPC 조회</Title>
                    </TitleField>
                    <Wrap>
                        <Box>
                            <GuideText>IPC 코드(국제특허분류코드) 혹은 키워드를 통해 원하시는 정보를 찾으실 수 있습니다.</GuideText>
                            <InlineField>
                                <SearchField onChange={filterData} onKeyPress={keyPress}/>
                                <Button onClick={ipcList}>검색</Button>
                            </InlineField>
                        </Box>
                    </Wrap>
                    <Table tableData={tableDataList} tableHeader={tableHeader} codeNumber={codeNumber}/>
                    <Pagination
                        activePage={page}
                        itemsCountPerPage={limit}
                        totalItemsCount={dataLength}
                        pageRangeDisplayed={5}
                        prevPageText={"<"}
                        nextPageText={">"}
                        onChange={handlePageChange}
                    />
                    <script async
                            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2633928464528168"
                            crossOrigin="anonymous"></script>
                    <ins className="adsbygoogle"
                         style={{display: "block"}}
                         data-ad-client="ca-pub-2633928464528168"
                         data-ad-slot="4662594652"
                         data-ad-format="auto"
                         data-full-width-responsive="true"></ins>
                    <script>
                        (adsbygoogle = window.adsbygoogle || []).push({});
                    </script>
                </Center>
                <Side/>
            </div>
            <Footer/>
        </>
    )
}

export default FindIPC;

const Wrap = styled.div`
    margin-top: 20px;
    display: flex;
    background-color: #FFFFFF;
    justify-content: center;
    margin-bottom: 13px;
`;

const Box = styled.div`
    width: 100%;
    display: block;
    background-color: #eeeeee;
    height: 130px;
    padding: 10px;
    border-radius: 10px;

    div {
        p {
            margin-left: 10px;
            margin-top: 30px;
        }
    }
`;

const TitleField = styled.div`
    margin-top: 20px;
    margin-left: 10px;
`;

const Title = styled.h1`
    font-weight: 1000;
    font-size: 25px;
`;

const Button = styled.button`
    width: 120px;
    height: 40px;
    font-size: 15px;
    background-color: #4671E9;
    color: aliceblue;
    font-weight: bold;
    border: 0px;
    border-radius: 5px;
    margin: 15px;
    cursor: pointer;
    &:hover {
        background-color: #2e5bee;
        color: white;
    }
`;

const SearchField = styled.input`
    width: calc(100% - 40%);
    height: 15px;
    border: 1px solid #ddd;
    margin: 15px 10px 20px 10px;
    font-size: 15px;
    padding: 12px;
    border-radius: 5px;
`;

const InlineField = styled.div`
    display: flex;
`;

const GuideText = styled.p`
    margin: 20px 10px 10px 20px;
    font-size: 14px;
`;
const Center = styled.div`
    width: 50%;
    @media ${({theme}) => theme.device.tabletL} {
        width: 90%;
    }
`;