import React from 'react';
import { createGlobalStyle } from "styled-components";
import Router from "./Router";
import axios from "axios";


const GlobalStyle = createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, menu, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  main, menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, main, menu, nav, section {
    display: block;
  }
  /* HTML5 hidden-attribute fix for newer browsers */
  *[hidden] {
    display: none;
  }
  body {
    line-height: 1;
  }
  menu, ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  a {
    text-decoration: none;
  }
  body {
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    font-family: 'Alfa Slab One', cursive;
    font-family: 'Noto Sans KR', sans-serif;
    color: ${({ theme }) => theme.colors.black};
    -ms-overflow-style: none;
  }
`;


function App() {

    // axios.interceptors.response.use(
    //     function (config){
    //         return config;
    //     }, function (error){
    //         if(error.response.state === 404){
    //
    //         } else if(error.response.state === 500){
    //
    //         } else if(error.response.state === 20){
    //             // open dart 일일 횟수 넘김
    //         } else if(error.response.state === 22){
    //             // 주식 시세 & 뉴스 일일 횟수 넘김
    //         }
    //
    //     }
    // )

  return (
    <>
        <Router />
        <GlobalStyle />
    </>
  );
}

export default App;
