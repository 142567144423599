import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

function ConnectTable(props){
    const navigate = useNavigate();
    const tableHeader = props.tableHeader;
    const tableData = props.tableData;
    const codeNumber = props.codeNumber;

    return(
        <TableWrap>
            <CustomTable>
                <colgroup>
                    <col style={{width: '10%'}} />
                    <col style={{width: '20%'}} />
                    <col style={{width: '10%'}} />
                    <col style={{width: '20%'}} />
                </colgroup>
                <thead>
                <tr>
                    {tableHeader && tableHeader.map(headerItem => (
                        <HeadCell>{headerItem}</HeadCell>
                    ))}
                </tr>
                </thead>
                <tbody>
                {tableData && tableData.map(item => (
                    <tr>
                        <BodyCell><a style={{display:"flex", justifyContent:"center", alignItems:"center"}}>{item.codeA}</a></BodyCell>
                        <BodyCell>{item.codeA_def_kr}</BodyCell>
                        <BodyCell><a style={{display:"flex", justifyContent:"center", alignItems:"center"}}>{item.codeB}</a></BodyCell>
                        <BodyCell>{item.codeB_def_kr}</BodyCell>
                    </tr>
                ))}
                </tbody>
            </CustomTable>
        </TableWrap>
    )
}
export default ConnectTable;
const TableWrap = styled.div`
    display: flex;
    justify-content: center;
    padding: 0px 0px 5px 0px;
    width: 100%;
`;

const CustomTable = styled.table`
    border-top: 2px solid #585858;
    border-spacing: 0;
    width: 100%;
`;

const HeadCell = styled.th`
    padding: 12px 10px 10px 10px;
    background-color: #dfdfdf;
    text-align: center;
    letter-spacing: 1px;
`;
const BodyCell = styled.td`
    padding: 13px;
    border-bottom: 2px solid rgba(49,49,49, 0.2);
    letter-spacing: 1px;
    line-height: 1.2;
    //text-align: center;
`;