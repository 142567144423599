import React from "react";
import styled from "styled-components";
import Side from "../../components/Side/Side";
import MenuBar from "../../components/Header/MenuBar";
import Footer from "../../components/Footer/Footer";
import CPCImg from "../../images/cpc구조.png";
import IndexingExample from "../../images/indexingExample.PNG";
import YSection from "../../images/y섹션구성.PNG";
import {Helmet} from "react-helmet";

function AboutCPC(){
    const goToResearchMall = () => {
        window.open("https://researchall.net/Report/ReportMall")
    }
    const goToCPCPopUp = () => {
        window.open("http://localhost:3000/detailCPC?CPC=A")
    }
    const goToGomGom = () => {
        window.open("https://ai.researchall.net/")
    }
    const goToResearch_Search = () => {
        window.open("https://researchall.net/popup/findCPC/A")
    }
    return(
        <>
            <Helmet>
                <title>CPC 내용</title>
                <meta name="description" content="CPC코드(특허분류코드)에 대한 정의, CPC코드(특허분류코드)의 구조, 등과 같은 CPC코드(특허분류코드)의 간략한 소개와 CPC코드(특허분류코드) 검색 시 검색한 CPC의 선도기업목록 보는 방법을 확인할 수 있습니다." />
                <meta name="robots" content="index, follow, max-snippet:-1" />
                <meta property="og:type" content="article" />
                <meta name="og:title" content="CPC 내용" />
                <meta property="og:description" content="CPC코드(특허분류코드)에 대한 정의, CPC코드(특허분류코드)의 구조, 등과 같은 CPC코드(특허분류코드)의 간략한 소개와 CPC코드(특허분류코드) 검색 시 검색한 CPC의 선도기업목록 보는 방법을 확인할 수 있습니다." />
                <meta name="og:site_name" content="search code" />
                <meta name="og:url" content="https://code.researchall.net/aboutCPC" />
            </Helmet>
            <MenuBar/>
            <div style={{display: "flex", justifyContent:"center"}}>
                <Side/>
                <InnerBox>
                    <TitleWord>CPC 기술의 선도기업 검색</TitleWord>
                    <DefaultWord>
                        <InnerTitle>검색 방법</InnerTitle>
                        <InnerWord>
                            CPC 조회 페이지에서 기술을 검색한 뒤, CPC 코드를 클릭하면 해당 기술의 상위 기술과, 하위기술 서술 <span
                            style={{color: "#587feb", fontWeight: "bold", cursor: "pointer"}}
                            onClick={goToCPCPopUp}>페이지</span>가 나타난다.
                            페이지의 하단에 <span style={{color: "#587feb", fontWeight: "bold", cursor: "pointer"}}
                                           onClick={goToResearch_Search}>해당 기술의 선도 기업 보기 버튼</span>을 클릭하면 ResearchALL
                            사이트에서 확인할 수 있다.
                        </InnerWord>
                        <InnerTitle>선도 기업의 분석 보고서 조회</InnerTitle>
                        <InnerWord>기업의 분석 보고서는 ResearchALL 사이트의 <span
                            style={{color: "#587feb", fontWeight: "bold", cursor: "pointer"}}
                            onClick={goToResearchMall}>Report Mall</span> 페이지에서
                            조회할 수 있다.</InnerWord><br/>
                        <InnerWord style={{color: "#646464"}}>* 해당 방법이나 관련 정보에 대해 궁금한 점이 있다면 <span
                            style={{color: "#587feb", fontWeight: "bold", cursor: "pointer"}} onClick={goToGomGom}>AI 챗봇 곰곰이</span>에게
                            물어보세요</InnerWord>
                    </DefaultWord>
                    <br/>
                    <script async
                            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2633928464528168"
                            crossOrigin="anonymous"></script>
                    <ins className="adsbygoogle"
                         style={{display: "block"}}
                         data-ad-client="ca-pub-2633928464528168"
                         data-ad-slot="4662594652"
                         data-ad-format="auto"
                         data-full-width-responsive="true"></ins>
                    <script>
                        (adsbygoogle = window.adsbygoogle || []).push({});
                    </script>
                    <br/>
                    <TitleWord>CPC의 소개</TitleWord>
                    <DefaultWord>
                        CPC는 IPC를 기반으로 하며, 유럽 특허청과 미국 특허청이 ECLA와 USPC를 보완한 특허분류체계로서,
                        효율적인 분류체계의 구축을 목적으로 공동 개발, 관리 및 유지되고 있으며
                        2013년 1월 1일부터 유럽특허청이 CPC를 공식적으로 사용하면서 CPC가 ECLA 및 USPC를 대체하게 되었다. <br/>
                        <br/>
                        우리나라는 2014년 시범 실시기를 거쳐 2015년 전면 도입하여 현재까지 특허 검색 및 분류 목적으로 CPC를 활용하고 있다. <br/>
                        CPC의 주요 장점은 IPC보다 분류 개소가 세분화되어 효율적인 선행문헌 검색이 가능하며
                        다양한 언어로 작성된 특허문서를 검색할 수 있다는 점이다.
                    </DefaultWord>
                    <br/>
                    <script async
                            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2633928464528168"
                            crossOrigin="anonymous"></script>
                    <ins className="adsbygoogle"
                         style={{display: "block"}}
                         data-ad-client="ca-pub-2633928464528168"
                         data-ad-slot="4662594652"
                         data-ad-format="auto"
                         data-full-width-responsive="true"></ins>
                    <script>
                        (adsbygoogle = window.adsbygoogle || []).push({});
                    </script>
                    <br/>
                    <TitleWord>CPC의 구조</TitleWord>
                    <DefaultWord>
                        CPC 분류표는 '메인 트렁크', '인덱싱 코드('2000 시리즈'라고도 불림)', 'Y 섹션'의 세 부분으로 나뉜다.
                        IPC와 비교할 때 CPC의 가장 큰 특징은 부가정보로 부여하는 인덱싱 코드(2000 시리즈)와 Y 섹션의 존재이다.
                        <br/>
                        <br/>
                        <InnerTitle>메인 트렁크(Main Trunk)</InnerTitle>
                        <InnerWord>
                            CPC는 IPC와 마찬가지로 섹션, 클래스, 서브 클래스, 및 메인그룹 또는 서브그룹의 계층적 구조이며
                            하나의 서브클래스 기호에 1~3자리 숫자, 사선(/), 및 2~6자리 숫자가 IPC 표준에 따라 구성됨으로써 IPC보다 세분화된 형태이다.
                            <ImgBox>
                                <img src={CPCImg} alt="CPC 메인 트렁크의 구조"/>
                            </ImgBox>
                        </InnerWord>
                        <InnerTitle>인덱싱 코드(Indexing codes) 또는 2000 시리즈(2000-series)</InnerTitle>
                        <InnerWord>
                            CPC의 인덱싱 코드는 IPC 분류표 및 유럽특허청에서 사용하던 ICO, KW 코드를
                            도입한 것으로 그룹 기호가 2000으로 시작하므로 2000 시리즈라고도 불린다.
                            세분화 인덱싱 코드는 계층적으로 상위의 메인 트렁크 그룹에 대해 기술적으로 더 깊고 많이 세분화된 코드이다.
                            <ImgBox>
                                <BigImg src={IndexingExample} alt="CPC 인덱싱 코드에 대한 예시"/>
                            </ImgBox>
                        </InnerWord>
                        <InnerTitle>Y 섹션(Y Section)</InnerTitle>
                        <InnerWord>
                            Y 섹션은 필요하다고 인정되나 기존의 IPC에 존재하지 안흔 기술 주제를 다루기 위해 유럽 특허청이 ECLA에 도입한 분류이다.
                            Y 섹션 기호는 부가정보로만 할당된다.
                            <ImgBox>
                                <BigImg src={YSection} alt="CPC Y섹션의 구성"/>
                            </ImgBox>
                        </InnerWord>
                    </DefaultWord>
                    <DefaultWord style={{color: "#646464"}}>*출처: CPC 분류 매뉴얼(2020년, 특허청)</DefaultWord>
                    <br/>
                    <script async
                            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2633928464528168"
                            crossOrigin="anonymous"></script>
                    <ins className="adsbygoogle"
                         style={{display: "block"}}
                         data-ad-client="ca-pub-2633928464528168"
                         data-ad-slot="4662594652"
                         data-ad-format="auto"
                         data-full-width-responsive="true"></ins>
                    <script>
                        (adsbygoogle = window.adsbygoogle || []).push({});
                    </script>
                </InnerBox>
                <Side/>
            </div>
            <Footer/>
        </>
    )
}

export default AboutCPC;

const InnerBox = styled.div`
    width: 50%;
    padding-top: 20px;
    @media ${({theme}) => theme.device.tabletL} {
        width: 90%;
    }
`;
const TitleWord = styled.h1`
    font-weight: bold;
    font-size: 20px;
    padding: 5px;
    letter-spacing: 1px;

`;
const DefaultWord = styled.p`
    font-size: 15px;
    padding: 5px;
    letter-spacing: 1px;
    line-height: 2.1;
`;
const InnerTitle = styled.p`
    font-size: 15px;
    padding-left: 20px;
    font-weight: bold;
    letter-spacing: 1px;
`

const InnerWord = styled.p`
    font-size: 15px;
    padding-left: 20px;
    letter-spacing: 1px;
    line-height: 2.1;
`;
const BigImg = styled.img`
    width: 65%;
    margin-top: 10px;
    margin-bottom: 10px;
    @media ${({theme}) => theme.device.tabletL} {
        width: 100%;
    }
`;
const ImgBox = styled.div`
    display: flex;
    justify-content: center;
`;