import React from "react";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import errorImg from "../../images/error.png";

function NotFound(){
    const navigate = useNavigate();
    const goToHome = () => {
        navigate('/searchCPC');
    }
    return(
        <div style={{display: "flex", textAlign: "center", justifyContent: "center", alignItems: "center"}}>
            <div>
                <ErrorImg src={errorImg}/>
                <Title>
                    선택한 페이지를 찾을 수 없습니다.
                </Title>
                <br/>
                <ContentText>
                    페이지가 존재하지 않거나, 사용할 수 없는 페이지입니다.
                    <br/><br/>
                    입력한 주소가 정확한지 다시 한번 확인해 주시기 바랍니다.
                </ContentText>
                <HomeButton onClick={goToHome}>홈으로 가기</HomeButton>
            </div>
        </div>
    )
}

export default NotFound;

const HomeButton = styled.button`
    padding: 10px 30px;
    font-size: 15px;
    margin-top: 15px;
    border-radius: 7px;
    background-color: #4671E9;
    border: 1px solid #4671E9;
    color: white;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0px 5px 15px rgba(249, 135, 59, 0.3);
    &:hover{
        
    }
`;

const Title = styled.h1`
    font-weight: bolder;
    font-size: 35px;
    padding: 10px;
    color: #666;
    word-spacing: 0px;
`;

const ContentText = styled.p`
    font-size: 20px;
    padding: 10px;
    color: #666;
    font-weight: 400;
    word-spacing: -2px;
    margin-top: 0px;
`;

const ErrorImg = styled.img`
`;