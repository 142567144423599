import React from "react";
import Side from "../../components/Side/Side";
import MenuBar from "../../components/Header/MenuBar";
import Footer from "../../components/Footer/Footer";
import styled from "styled-components";
import IPCImg from "../../images/IPC구조.PNG"
import {Helmet} from "react-helmet";

function AboutIPC(){
    const goToGomGom = () => {
        window.open("https://ai.researchall.net/")
    }

    return(
        <>
            <Helmet>
                <title>IPC 내용</title>
                <meta name="description" content="IPC(국제특허분류코드)의 정의, IPC(국제특허분류코드)의 구조, 등과 같은 IPC(국제특허분류코드)의 간략한 소개와 챗봇 사용법에 대한 내용을 확인할 수 있습니다."/>
                <meta name="robots" content="index, follow, max-snippet:-1" />
                <meta property="og:type" content="article" />
                <meta name="og:title" content="IPC 내용" />
                <meta property="og:description" content="IPC(국제특허분류코드)의 정의, IPC(국제특허분류코드)의 구조, 등과 같은 IPC(국제특허분류코드)의 간략한 소개와 챗봇 사용법에 대한 내용을 확인할 수 있습니다."/>
                <meta name="og:site_name" content="search code" />
                <meta name="og:url" content="https://code.researchall.net/aboutIPC" />
            </Helmet>
            <MenuBar />
            <div style={{display:"flex", justifyContent:"center"}}>
                <Side />
                <Center>
                    <TitleWord>IPC의 소개</TitleWord>
                    <DefaultWord>
                        IPC(International Patent Classification, 국제특허분류)는 발명의 기술분야를 나타내는 국제적으로 통일된 특허분류체계로,
                        IPC는 특허 문헌에 대해 국제적으로 통일된 분류를 부여하며 특허 문헌 검색의 효과적인 조사도구로 활용된다.
                        특허문헌의 분류, 검색을 용이하게 하도록 국내의 모든 출원건에 대하여 IPC를 부여하고 있다.
                    </DefaultWord>
                    <br/>
                    <script async
                            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2633928464528168"
                            crossOrigin="anonymous"></script>
                    <ins className="adsbygoogle"
                         style={{display: "block"}}
                         data-ad-client="ca-pub-2633928464528168"
                         data-ad-slot="4662594652"
                         data-ad-format="auto"
                         data-full-width-responsive="true"></ins>
                    <script>
                        (adsbygoogle = window.adsbygoogle || []).push({});
                    </script>
                    <br/>
                    <TitleWord>IPC의 구조</TitleWord>
                    <DefaultWord>IPC는 섹션(Section), 클래스(Class), 서브 클래스(Sub Class), 메인 그룹(Main Group), 서브 그룹(Sub Group)으로
                        이루어진 5단계의 계층 구조를 가진다.</DefaultWord>
                    <ImgBox>
                        <BigImg src={IPCImg} alt="IPC 코드의 구조"/>
                    </ImgBox>
                    <DefaultWord>
                        *출처: 특허청 CPC 및 IPC 분류코드 페이지(https://www.kipo.go.kr/ko/kpoContentView.do?menuCd=SCD0200269)
                    </DefaultWord>
                    <br/>
                    <br/>
                    <TitleWord>추가 정보</TitleWord>
                    <DefaultWord>
                        정보에 대한 추가적인 궁금한 사항은 <span style={{fontWeight: "bold", color: "#587feb", cursor: "pointer"}} onClick={goToGomGom}>AI 챗봇 곰곰이</span>에게 물어보세요
                    </DefaultWord>
                    <br/>
                </Center>
                <Side/>
            </div>
            <Footer/>
        </>
    )
}

export default AboutIPC;
const TitleWord = styled.h1`
    font-weight: bold;
    font-size: 20px;
    padding: 5px;
    letter-spacing: 1px;
`;
const DefaultWord = styled.p`
    font-size: 15px;
    padding: 5px;
    letter-spacing: 1px;
    line-height: 2.1;
`;
const ImgBox = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
`;
const BigImg = styled.img`
    width: 40%;
    margin-top: 10px;
    margin-bottom: 10px;
    @media ${({theme}) => theme.device.tabletL} {
        width: 100%;
    }
`;
const Center = styled.div`
    width: 50%;
    padding-top: 20px;
    @media ${({theme}) => theme.device.tabletL} {
        width: 90%;
    }
`;