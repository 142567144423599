import React from "react";
import Side from "../../components/Side/Side";
import MenuBar from "../../components/Header/MenuBar";
import Footer from "../../components/Footer/Footer";
import styled from "styled-components";
import HSImg from "../../images/hs코드구조.PNG";
import {Helmet} from "react-helmet";

function AboutHS(){
    return(
        <>
            <Helmet>
                <title>HS 내용</title>
                <meta name="description" content="HS 코드(품목분류코드)의 정의, HS 코드(품목분류코드)의 구조 등과 같은 HS 코드(품목분류코드)의 간략한 소개를 확인할 수 있습니다." />
                <meta name="robots" content="index, follow, max-snippet:-1" />
                <meta property="og:type" content="article" />
                <meta name="og:title" content="HS 내용" />
                <meta property="og:description" content="HS 코드(품목분류코드)의 정의, HS 코드(품목분류코드)의 구조 등과 같은 HS 코드(품목분류코드)의 간략한 소개를 확인할 수 있습니다." />
                <meta name="og:site_name" content="search code" />
                <meta name="og:url" content="https://code.researchall.net/aboutHS" />
            </Helmet>
            <MenuBar />
            <div style={{display:"flex", justifyContent:"center"}}>
                <Side />
                <Center>
                    <TitleWord>HS(품목분류)의 소개</TitleWord>
                    <DefaultWord>
                        HS는 1988년 국제협약으로 체택된 국제통일상품분류체계(Harmonized Commodity Description and Coding System)의 약칭이다.
                        국제통일상품분류체계는 대외 무역거래 상품을 숫자 코드로 분류하여 상품분류 체계를 통일함으로써 국제무역을 원활하게 하고 관세율 적용에 일관성을 유지하기 위한 것으로, 관세나
                        무역 통계, 우송, 보험 등 다양한 목적에 사용된다. <br/>
                        <br/>
                        국제 협약에 따라 HS 코드는 10자리까지 사용할 수 있으며 6자리까지는 국제 공통으로 사용하는 코드이다.
                        대한민국에서는 세부 분류를 위해 국제 공통 코드인 6자리 뒤에 4자리를 추가해서 사용하는 HSK를 사용하고 있다.
                    </DefaultWord>
                    <br/>
                    <script async
                            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2633928464528168"
                            crossOrigin="anonymous"></script>
                    <ins className="adsbygoogle"
                         style={{display: "block"}}
                         data-ad-client="ca-pub-2633928464528168"
                         data-ad-slot="4662594652"
                         data-ad-format="auto"
                         data-full-width-responsive="true"></ins>
                    <script>
                        (adsbygoogle = window.adsbygoogle || []).push({});
                    </script>
                    <br/>
                    <TitleWord>HS코드(품목분류코드)의 구조</TitleWord>
                    <DefaultWord>
                        HS 코드는 국제적으로 6자리로 구성되며 대한민국에서 사용하는 HSK 코드는 4자리가 추가된다. 6자리는 국제공통이며 모든 나라가 동일하다. 6자리는 2자리마다 각각
                        류(chapter), 호(heading), 소호(subheading)로 나뉜다.
                        <ImgBox>
                            <BigImg src={HSImg} alt="HS 코드 구조"/>
                        </ImgBox>
                    </DefaultWord>
                    < br/>
                    <DefaultWord>*출처: 관세청 네이버 블로그
                        (https://m.blog.naver.com/k_customs/
                        222117536899)
                    </DefaultWord>
                    <br/>
                </Center>
                <Side/>
            </div>
            <Footer/>
        </>
    )
}

export default AboutHS;
const TitleWord = styled.h1`
    font-weight: bold;
    font-size: 20px;
    padding: 5px;
    letter-spacing: 1px;
`;
const DefaultWord = styled.p`
    font-size: 15px;
    padding: 5px;
    letter-spacing: 1px;
    line-height: 2.1;
`;
const ImgBox = styled.div`
    display: flex;
    justify-content: center;
`;
const BigImg = styled.img`
    width: 65%;
    margin-top: 10px;
    margin-bottom: 10px;
    @media ${({theme}) => theme.device.tabletL} {
        width: 100%;
    }
`;
const Center = styled.div`
    width: 50%;
    padding-top: 20px;
    @media ${({theme}) => theme.device.tabletL} {
        width: 90%;
    }
`;