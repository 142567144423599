import React, {useEffect, useState} from "react";
import axios from 'axios';
import styled from "styled-components";
import Footer from "../../components/Footer/Footer"
import MenuBar from "../../components/Header/MenuBar";
import Table from "../../components/Table/Table";
import Pagination from "react-js-pagination";
import "../../components/style/Pagination.css"
import checkYesIcon from "../../images/tab_check_y.png";
import checkNoIcon from "../../images/tab_check_n.png";
import Side from "../../components/Side/Side";
import {Helmet} from "react-helmet";
import News from "./News"
import LV1Table from "../../components/Table/LV1Table";

function FindHS(){
    // table 변수
    const [tableDataList , setTableDataList] = useState([]);
    const [secondDataList, setSecondDataList] = useState([]);
    const tableHeader = ["HS 코드", "설명"];
    // search 변수
    const [searchTarget, setSearchTarget] = useState("");
    // const [firstClass, setFirstClass] = useState("")
    // const [secondClass, setSecondClass] = useState("")
    // const [thirdClass, setThirdClass] = useState("")
    // pagination 변수
    const [page, setPage] = useState(1);
    // 테이블 데이터 수 limit - 기본값 10개
    const [limit, setLimit] = useState(10);
    const [dataLength, setDataLength] = useState();
    const [secondDataLength, setSecondDataLength] = useState();
    // 코드 종류
    const [codeNumber, setCodeNumber] = useState();
    // tab 변수
    const [tabIndex, setTabIndex] = useState(0);
    // select 변수
    // const [lv1Data, setLv1Data] = useState()
    // const [lv2Data, setLv2Data] = useState()
    // const [lv3Data, setLv3Data] = useState()
    // 뉴스 변수
    const [newsData, setNewsData] = useState()

    //hs code list
    const hsList = () =>{
        axios.post("/api/hs/hsList",{page:page, limit:limit, searchTarget:searchTarget})
            .then((response)=>{
                // console.log(response.data);
                setCodeNumber(response.data.whatIsThisCode);
                setDataLength(response.data.totalCount)
                const changeData = response.data.data.map( item => ({
                    code: item.hs_code,
                    def_kr: item.def_kr
                }))
                setTableDataList(changeData)
            })
            .catch((error)=>{
                console.error("Error hsList axios request", error)
            })
    }

    const hsSecondList = () => {
        axios.post('/api/hs/hsLv1Data', {page:page, limit:limit})
            .then((response) => {
                // console.log(response.data)
                setSecondDataLength(response.data.totalCount)
                const changeData = response.data.data.map(item => ({
                    code: item.hs_code,
                    def_kr: item.def_kr
                }))
                setSecondDataList(changeData)
            })
    }
    useEffect(()=>{
        if(tabIndex === 0){
            hsList()
        } else{
            hsSecondList()
        }
    },[page, tabIndex])

    // ---------------------------------------------------------------------------------------------------------------------------------------
    // select list
    // const selectHSList = () => {
    //     axios.post("/api/hs/classHSList", {page:page, limit:limit, firstClass:firstClass, secondClass:secondClass, thirdClass:thirdClass})
    //         .then((response) => {
    //             // console.log(response.data)
    //             setCodeNumber(response.data.whatIsThisCode);
    //             setDataLength(response.data.totalCount)
    //             const changeData = response.data.data.map( item => ({
    //                 code: item.hs_code,
    //                 def_kr: item.def_kr,
    //             }))
    //             setTableDataList(changeData)
    //         })
    //         .catch((error) => {
    //             console.log("Error select hs list request", error)
    //         })
    // }
    //
    // const firstClassList = () => {
    //     axios.post("/api/hs/firstClass")
    //         .then((response) => {
    //             // console.log(response.data.data)
    //             setLv1Data(response.data.data)
    //         })
    //         .catch((error) => {
    //             console.log("Error hs first class request", error)
    //         })
    // }
    //
    // const secondClassList = () => {
    //     axios.post("/api/hs/secondClass",{firstClass: firstClass})
    //         .then((response) => {
    //             // console.log(response.data.data)
    //             setLv2Data(response.data.data)
    //         })
    //         .catch((error) => {
    //             console.log("Error hs second class request", error)
    //         })
    // }
    //
    // const thirdClassList = () => {
    //     axios.post("/api/hs/thirdClass",{secondClass:secondClass})
    //         .then((response) => {
    //             // console.log(response.data.data)
    //             setLv3Data(response.data.data)
    //         })
    //         .catch((error) => {
    //             console.log("Error hs third class request", error)
    //         })
    // }
    //
    // useEffect(() => {
    //     // console.log(firstClass, secondClass, thirdClass)
    //     if(tabIndex === 1){
    //         selectHSList()
    //         firstClassList()
    //         secondClassList()
    //         thirdClassList()
    //     }
    // },[page, tabIndex, firstClass, secondClass, thirdClass])

    // search filtering
    const filterData = (e) => {
        setSearchTarget(e.target.value)
    }

    //pagination
    const handlePageChange = (page) => {
        setPage(page)
    }

    //keypress event
    const keyPress = (e) => {
        if(e.key === 'Enter'){
            setPage(1)
            hsList()
        }
    }
    // change Tab
    const changeToFirstTab = () => {
        setTabIndex(0)
        setPage(1)
    }
    const changeToSecondTab = () => {
        setTabIndex(1)
        setPage(1)
    }
    // 대분류 selected 값 저장
    // const lv1SelectChange = (e) => {
    //     console.log("lv1 change")
    //     setFirstClass(e.target.value);
    //     setPage(1)
    //     setSecondClass("")
    //     setThirdClass("")
    //     secondClassList()
    // }
    // // 중분류 selected 값 저장
    // const lv2SelectChange = (e) => {
    //     setSecondClass(e.target.value)
    //     setPage(1)
    //     setThirdClass("")
    //     thirdClassList()
    // }
    // // 소분류 selected 값 저장
    // const lv3SelectChange = (e) => {
    //     setThirdClass(e.target.value)
    //     setPage(1)
    // }

    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------
    // 뉴스 API
    useEffect(() => {
        axios.get('https://apis.data.go.kr/B410001/kotra_overseasMarketNews/ovseaMrktNews/ovseaMrktNews?ServiceKey=8kkeRphuvmFEMT8hcGzhJ4G3nocMWXYFWlJXFrh4X/T8odDu7fdcNIv/Is/FMNrKc/wUKR8pTAKJ34UghFeH3Q==&numOfRows=3&pageNo=1')
            .then((response) => {
                console.log(response)
                console.log(response.data.response.body.itemList.item)
                setNewsData(response.data.response.body.itemList.item)
            })
            .catch((error) => {
                console.log("Error hs third class request", error)
            })
    }, []);


    return(
        <>
            <Helmet>
                <title>HS 검색</title>
                <meta name="description" content="HS 코드(품목분류코드)를 검색하여 HS 코드(품목분류코드)와 그에 대한 설명을 조회할 수 있으며 검색한 HS 코드(품목분류코드)의 상하위 기술코드를 확인할 수 있습니다." />
                <meta name="robots" content="index, follow, max-snippet:-1" />
                <meta property="og:type" content="article" />
                <meta name="og:title" content="HS 검색" />
                <meta property="og:description" content="HS 코드(품목분류코드)를 검색하여 HS 코드(품목분류코드)와 그에 대한 설명을 조회할 수 있으며 검색한 HS 코드(품목분류코드)의 상하위 기술코드를 확인할 수 있습니다." />
                <meta name="og:site_name" content="search code" />
                <meta name="og:url" content="https://code.researchall.net/searchHS" />
            </Helmet>
            <MenuBar />
            <div style={{display:"flex", justifyContent:"center"}}>
                <NewsSide>
                    <News newsData={newsData} />
                </NewsSide>
                <Center>
                    <TitleField>
                        <Title>HS(품목 분류 코드) 조회</Title>
                    </TitleField>
                    <Wrap>
                        <Box>
                            {/*두 가지 방법 으로 나눠서 검색할 경우*/}
                            <Button onClick={changeToFirstTab} style={{
                                backgroundColor: (tabIndex === 0 ? '#4671E9' : '#F8F8F8'),
                                color: (tabIndex === 0 ? 'aliceblue' : '#888888')
                            }}>
                                {tabIndex === 0 &&
                                    <ButtonBox>
                                        <img src={checkYesIcon}
                                             style={{width: "20px", height: "20px", marginRight: "10px"}}></img>
                                        <p style={{margin: 0}}>코드 검색</p>
                                    </ButtonBox>}
                                {tabIndex === 1 &&
                                    <ButtonBox>
                                        <img src={checkNoIcon}
                                             style={{width: "20px", height: "20px", marginRight: "10px"}}/>
                                        <p style={{margin: 0}}>코드 검색</p>
                                    </ButtonBox>
                                }
                            </Button>
                            <Button onClick={changeToSecondTab} style={{
                                backgroundColor: (tabIndex === 1 ? '#4671E9' : '#F8F8F8'),
                                color: (tabIndex === 1 ? 'aliceblue' : '#888888')
                            }}>
                                {tabIndex === 1 &&
                                    <ButtonBox>
                                        <img src={checkYesIcon}
                                             style={{width: "20px", height: "20px", marginRight: "10px"}}></img>
                                        <p style={{margin: 0}}>분류 검색</p>
                                    </ButtonBox>}
                                {tabIndex === 0 &&
                                    <ButtonBox>
                                        <img src={checkNoIcon}
                                             style={{width: "20px", height: "20px", marginRight: "10px"}}/>
                                        <p style={{margin: 0}}>분류 검색</p>
                                    </ButtonBox>
                                }
                            </Button>
                            {/*----------------------------------------------------------------------------------------------------*/}
                            <div>
                                {/*코드 검색*/}
                                {tabIndex === 0 &&
                                    <div>
                                        <GuideText style={{marginTop: "18px"}}>
                                            ※ HS코드(품목분류코드) 혹은 키워드를 통해 원하시는 정보를 검색할 수 있습니다.
                                        </GuideText>
                                        <GuideText style={{marginTop: 0, marginBottom: 0}}>
                                            ※ 예시) 01, 01012, 동물...
                                        </GuideText>
                                        <InlineField>
                                            <SearchField onChange={filterData} onKeyPress={keyPress}/>
                                            <Button onClick={hsList}>검색</Button>
                                        </InlineField>
                                    </div>
                                }
                                {/*분류 검색*/}
                                {tabIndex === 1 &&
                                    <div>
                                        <GuideText style={{marginTop: "40px", marginBottom: "15px"}}>
                                            ※ HS 코드(품목분류코드)의 분류 설정을 통해 원하시는 정보를 검색할 수 있습니다.
                                        </GuideText>
                                        {/*<div style={{display: "flex", alignItems: "center"}}>*/}
                                        {/*    <SelectBox defaultValue={""} onChange={lv1SelectChange}*/}
                                        {/*               style={{marginLeft: "20px"}} value={firstClass}>*/}
                                        {/*        <option value={""}>대분류</option>*/}
                                        {/*        {lv1Data && lv1Data.map((lv1_item, index) => (*/}
                                        {/*            <option key={index}*/}
                                        {/*                    value={lv1_item.hs_code}>{lv1_item.hs_code}</option>*/}
                                        {/*        ))}*/}
                                        {/*    </SelectBox>*/}
                                        {/*    <SelectBox defaultValue={""} onChange={lv2SelectChange} value={secondClass}>*/}
                                        {/*        <option value={""}>중분류</option>*/}
                                        {/*        {lv2Data && lv2Data.map((lv2_item, index) => (*/}
                                        {/*            <option key={index}*/}
                                        {/*                    value={lv2_item.hs_code}>{lv2_item.hs_code}</option>*/}
                                        {/*        ))}*/}
                                        {/*    </SelectBox>*/}
                                        {/*    <SelectBox defaultValue={""} onChange={lv3SelectChange} value={thirdClass}>*/}
                                        {/*        <option value={""}>소분류</option>*/}
                                        {/*        {lv3Data && lv3Data.map((lv3_item, index) => (*/}
                                        {/*            <option key={index}*/}
                                        {/*                    value={lv3_item.hs_code}>{lv3_item.hs_code}</option>*/}
                                        {/*        ))}*/}
                                        {/*    </SelectBox>*/}
                                        {/*</div>*/}
                                    </div>
                                }
                            </div>
                        </Box>
                    </Wrap>
                    {tabIndex === 0 &&
                        <div>
                            <Table tableData={tableDataList} tableHeader={tableHeader} codeNumber={codeNumber}/>
                            <Pagination
                                activePage={page}
                                itemsCountPerPage={limit}
                                totalItemsCount={dataLength}
                                pageRangeDisplayed={5}
                                prevPageText={"<"}
                                nextPageText={">"}
                                onChange={handlePageChange}
                            />
                        </div>
                    }
                    {tabIndex === 1 &&
                        <div>
                            <LV1Table tableData={secondDataList} tableHeader={tableHeader} codeNumber={codeNumber} />
                            <Pagination
                                activePage={page}
                                itemsCountPerPage={limit}
                                totalItemsCount={secondDataLength}
                                pageRangeDisplayed={5}
                                prevPageText={"<"}
                                nextPageText={">"}
                                onChange={handlePageChange}
                            />
                        </div>
                    }
                    <script async
                            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2633928464528168"
                            crossOrigin="anonymous"></script>
                    <ins className="adsbygoogle"
                         style={{display: "block"}}
                         data-ad-client="ca-pub-2633928464528168"
                         data-ad-slot="4662594652"
                         data-ad-format="auto"
                         data-full-width-responsive="true"></ins>
                    <script>
                        (adsbygoogle = window.adsbygoogle || []).push({});
                    </script>
                </Center>
                <Side/>
            </div>
            <Footer/>
        </>
    )
}

export default FindHS;

const Wrap = styled.div`
    margin-top: 20px;
    display: flex;
    background-color: #FFFFFF;
    justify-content: center;
    margin-bottom: 13px;
`;

const Box = styled.div`
    width: 100%;
    display: block;
    background-color: #eeeeee;
    height: 200px;
    padding: 10px;
    border-radius: 10px;
`;

const TitleField = styled.div`
    margin-top: 20px;
    margin-left: 10px;
`;

const Title = styled.h1`
    font-weight: 1000;
    font-size: 25px;
`;

const Button = styled.button`
    width: 15%;
    height: 40px;
    font-size: 15px;
    background-color: #4671E9;
    color: aliceblue;
    font-weight: bold;
    border: 0px;
    border-radius: 5px;
    margin: 15px;
    cursor: pointer;
    &:hover {
        background-color: #2e5bee;
        color: white;
    }
    @media ${({theme}) => theme.device.tabletL} {
        width: 20%;
    }
`;

const SearchField = styled.input`
    width: calc(100% - 40%);
    height: 15px;
    border: 1px solid #ddd;
    margin: 15px 10px 20px 10px;
    font-size: 15px;
    padding: 12px;
    border-radius: 5px;
`;

const InlineField = styled.div`
    display: flex;
`;

const GuideText = styled.p`
    margin: 20px 10px 10px 20px;
    font-size: 14px;
`;

const ButtonBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const SelectBox = styled.select`
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 0 10px;
    height: 42px;
    width: 23%;
    font-size: 15px;
    margin-right: 15px;
    text-overflow: ellipsis;
    option {
        width: 200px;
        data-limit: 37;
    }
`;
const Center = styled.div`
    width: 55%;
    @media ${({theme}) => theme.device.tabletL} {
        width: 90%;
    }
`;

const NewsSide = styled.div`
    width: 20%;
`;