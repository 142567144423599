import React from 'react';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import FindCPC from "./pages/CPC/FindCPC";
import CPCPopUp from "./pages/CPC/CPCPopUp";
import FindKSIC from "./pages/KSIC/FindKSIC";
import FindIPC from "./pages/IPC/FindIPC";
import IPCPopUp from "./pages/IPC/IPCPopUp";
import FindHS from "./pages/HS/FindHS";
import HSPopUp from "./pages/HS/HSPopUp";
import CPC_IPC_Link from "./pages/Link/CPC_IPC_Link";
import KSIC_HS_Link from "./pages/Link/KSIC_HS_Link";
import AboutCPC from "./pages/CPC/AboutCPC";
import AboutIPC from "./pages/IPC/AboutIPC";
import AboutKSIC from "./pages/KSIC/AboutKSIC";
import AboutHS from "./pages/HS/AboutHS";
import NotFound from "./pages/Error/NotFound";
import Enter from "./pages/Enter/Enter";
import Stock from "./pages/Stock/Stock";

const Router = () => {
    return(
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Navigate to="/searchCPC" />} />
                <Route path="/searchCPC" element={<FindCPC />} />
                <Route path="/detailCPC" element={<CPCPopUp />} />
                <Route path="/searchKSIC" element={<FindKSIC />} />
                <Route path="/searchIPC" element={<FindIPC />} />
                <Route path="/detailIPC" element={<IPCPopUp />} />
                <Route path="/searchHS" element={<FindHS />} />
                <Route path="/detailHS" element={<HSPopUp />} />
                <Route path="/search_CPC_IPC_Link" element={<CPC_IPC_Link />} />
                <Route path="/search_KSIC_HS_Link" element={<KSIC_HS_Link />} />
                <Route path="/aboutCPC" element={<AboutCPC />} />
                <Route path="/aboutIPC" element={<AboutIPC />} />
                <Route path="/aboutKSIC" element={<AboutKSIC />} />
                <Route path="/aboutHS" element={<AboutHS />} />
                <Route path="/enterprise" element={<Enter />} />
                <Route path="/Stock" element={<Stock />} />
                <Route path="/*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    )
}

export default Router;