import React from "react";
import Side from "../../components/Side/Side";
import MenuBar from "../../components/Header/MenuBar";
import Footer from "../../components/Footer/Footer";
import styled from "styled-components";
import {Helmet} from "react-helmet";

function AboutKSIC(){
    return(
        <>
            <Helmet>
                <title>KSIC 내용</title>
                <meta name="description" content="KSIC코드(한국표준산업분류코드)의 정의, KSIC코드(한국표준산업분류코드)의 구조, 등과 같은 KSIC코드(한국표준산업분류코드)의 간략한 소개에 대한 내용의 확인할 수 있습니다."/>
                <meta name="robots" content="index, follow, max-snippet:-1" />
                <meta property="og:type" content="article" />
                <meta name="og:title" content="KSIC 내용" />
                <meta property="og:description" content="KSIC코드(한국표준산업분류코드)의 정의, KSIC코드(한국표준산업분류코드)의 구조, 등과 같은 KSIC코드(한국표준산업분류코드)의 간략한 소개에 대한 내용의 확인할 수 있습니다."/>
                <meta name="og:site_name" content="search code" />
                <meta name="og:url" content="https://code.researchall.net/aboutKSIC" />
            </Helmet>
            <MenuBar/>
            <div style={{display:"flex", justifyContent:"center"}}>
                <Side />
                <Center>
                    <TitleWord>KSIC(한국표준산업분류코드)의 소개</TitleWord>
                    <DefaultWord>
                        KSIC(한국표준산업분류)는 산업관련 통계자료의 정확성, 비교성 등을 확보하기 위하여 작성된 것으로서 1963년 3월에 경제활동 부문 중에서 우선 광업과 제조업 부문에 대한 산업분류를
                        제정하였고,
                        이듬해 4월에 제조업 이외 부문에 대한 산업분류를 추가로 제정함으로써 우리나라의 표준산업분류 체계를 완성하였다.
                        이렇게 제정된 한국표준산업분류는 유엔(UN)의 국제표준산업분류(1차 개정: 1958년)에 기초하여 작성됐다.
                    </DefaultWord>
                    <br/>
                    <script async
                            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2633928464528168"
                            crossOrigin="anonymous"></script>
                    <ins className="adsbygoogle"
                         style={{display: "block"}}
                         data-ad-client="ca-pub-2633928464528168"
                         data-ad-slot="4662594652"
                         data-ad-format="auto"
                         data-full-width-responsive="true"></ins>
                    <script>
                        (adsbygoogle = window.adsbygoogle || []).push({});
                    </script>
                    <br/>
                    <TitleWord>KSIC(한국표준산업분류코드)의 분류 기준</TitleWord>
                    <DefaultWord>
                        산업분류는 생산단위가 주로 수행하고 있는 산업활동으로 다음과 같은 분류 기준에 의하여 적용된다.<br/>
                        1. 산출물(생산된 재화 또는 제공된 서비스)의 특성<br/>
                        : 산출물의 물리적 구성, 가공 단계, 수요처, 기능 및 용도<br/>
                        2. 투입물의 특성<br/>
                        : 원재료, 생산 공정, 생산기술 및 시설 등<br/>
                        3. 생산활동의 일반적인 결합형태<br/>
                    </DefaultWord>
                    <br/>
                    <script async
                            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2633928464528168"
                            crossOrigin="anonymous"></script>
                    <ins className="adsbygoogle"
                         style={{display: "block"}}
                         data-ad-client="ca-pub-2633928464528168"
                         data-ad-slot="4662594652"
                         data-ad-format="auto"
                         data-full-width-responsive="true"></ins>
                    <script>
                        (adsbygoogle = window.adsbygoogle || []).push({});
                    </script>
                    <br/>
                    <TitleWord>KSIC(한국표준산업분류코드)의 구조</TitleWord>
                    <DefaultWord>
                        분류 구조는 대분류(1자리, 영문대문자), 중분류(2자리 숫자), 소분류(3자리 숫자), 세분류(4자리 숫자), 세세분류(5자리 숫자)로 총 5단계로 구성되어 있다.
                    </DefaultWord>
                    <br/>
                    <DefaultWord>
                        *출처: 통계청 경제분류 개요
                        (http://kssc.kostat.go.kr/ksscNew_web/kssc/
                        main/main.do?gubun=1#)
                    </DefaultWord>
                    <br/>
                </Center>
                <Side/>
            </div>
            <Footer/>
        </>
    )
}

export default AboutKSIC;
const TitleWord = styled.h1`
    font-weight: bold;
    font-size: 20px;
    padding: 5px;
    letter-spacing: 1px;
`;
const DefaultWord = styled.p`
    font-size: 15px;
    padding: 5px;
    letter-spacing: 1px;
    line-height: 2.1;
`;
const Center = styled.div`
    width: 50%;
    padding-top: 20px;
    @media ${({theme}) => theme.device.tabletL} {
        width: 90%;
    }
`;