import React, {useState} from "react";
import styled from "styled-components";
// import {ReactComponent as DetailIcon} from "../../images/ico_link.svg";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function LevelShowTable(props){
    const tableHeader = props.tableHeader;
    const tableData = props.tableData;
    const tabIndex = props.tabIndex
    // const codeNumber = props.codeNumber;
    const [openRows, setOpenRows] = useState([]);

    const tableOpen = (index) => {
        setOpenRows(prevState => {
            const newState = [...prevState];
            newState[index] = !newState[index];
            return newState;
        })
    }

    return(
        <TableWrap>
            <CustomTable>
                <thead style={{width:"100%"}}>
                {tabIndex === 0 &&
                    <tr>
                        {tableHeader && tableHeader.map(headerItem => (
                            <HeadCell>{headerItem}</HeadCell>
                        ))}
                    </tr>
                }
                {tabIndex === 1 &&
                    <tr>
                        <HeadCell>KSIC 코드</HeadCell>
                        <HeadCell>세분류</HeadCell>
                        <HeadCell>세세분류</HeadCell>
                    </tr>
                }
                </thead>
                {tableData && tableData.map((item, index) => (
                    <tbody key={index} style={{width: "100%"}}>
                    <tr>
                            <BodyCell onClick={() => tableOpen(index)}>
                                <a style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                <FontAwesomeIcon icon={openRows[index] ? faAngleUp: faAngleDown} style={{ marginRight: "3px", color: "#595959"}}/>
                                    {item.code}
                                </a>
                            </BodyCell>
                            {tabIndex === 0 &&
                                <>
                                    <BodyCell onClick={() => tableOpen(index)}>{item.ex1}</BodyCell>
                                    <BodyCell onClick={() => tableOpen(index)}>{item.ex2}</BodyCell>
                                    <BodyCell onClick={() => tableOpen(index)}>{item.ex3}</BodyCell>
                                </>
                            }
                            {tabIndex === 1 &&
                                <>
                                    <BodyCell onClick={() => tableOpen(index)}>{item.ex4}</BodyCell>
                                    <BodyCell onClick={() => tableOpen(index)}>{item.def_kr}</BodyCell>
                                </>
                            }
                        </tr>
                        {openRows[index] &&
                            <td colSpan={4}>
                                <DropBox>
                                    <div>
                                        <DropInner>
                                            <DetailText>{item.level1}:</DetailText>
                                            <DetailText>{item.ex1}</DetailText>
                                        </DropInner>
                                        <DropInner>
                                            <DetailText>{item.level2}:</DetailText>
                                            <DetailText>{item.ex2}</DetailText>
                                        </DropInner>
                                        <DropInner>
                                            <DetailText>{item.level3}:</DetailText>
                                            <DetailText>{item.ex3}</DetailText>
                                        </DropInner>
                                        <DropInner>
                                            <DetailText>{item.level4}:</DetailText>
                                            <DetailText>{item.ex4}</DetailText>
                                        </DropInner>
                                        <DropInner>
                                            <DetailText style={{fontWeight:"bold"}}>{item.code}:</DetailText>
                                            <DetailText style={{fontWeight:"bold"}}>{item.def_kr}</DetailText>
                                        </DropInner>
                                    </div>
                                </DropBox>
                            </td>
                        }
                    </tbody>
                ))}
            </CustomTable>
        </TableWrap>
    )
}

export default LevelShowTable;

const TableWrap = styled.div`
    display: flex;
    justify-content: center;
    padding: 0px 0px 5px 0px;
    width: 100%;
`;

const CustomTable = styled.table`
    border-top: 2px solid #585858;
    border-spacing: 0;
    width: 1200px;
`;

const HeadCell = styled.th`
    padding: 12px 10px 10px 10px;
    background-color: #dfdfdf;
    text-align: center;
    width: 300px;
    letter-spacing: 1px;
`;
const BodyCell = styled.td`
    padding: 13px;
    border-bottom: 2px solid rgba(49,49,49, 0.2);
    text-align: center;
    cursor: pointer;
    letter-spacing: 1px;
    //line-height: 1.2;
`;
const DropBox = styled.div`
    text-align: center;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    padding-bottom: 10px;
    border-bottom: 2px solid rgba(49,49,49, 0.2);;
`;
const DropInner = styled.div`
    display: flex;
`;
const DetailText = styled.p`
    padding: 5px;
`;