import React from "react";
import styled from "styled-components";

function News(props){
    const newsData = props.newsData

    const goToNews = (item) => {
        window.open(item.kotraNewsUrl)
    }

    return(
        <Outer>
            <NewsWrapper>
                <Title>해외 시장 뉴스</Title>
                {newsData && newsData.map(item =>
                    <div style={{cursor:"pointer"}} onClick={() => goToNews(item)}>
                        <NewsTitle>{item.newsTitl}</NewsTitle>
                        <NewsSum>{item.cntntSumar}</NewsSum>
                        <NewsDate>{item.othbcDt}</NewsDate>
                        <Box />
                    </div>
                )}
            </NewsWrapper>
        </Outer>
    )
}

export default News;

const Outer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const NewsWrapper = styled.div`
    width: 80%;
    margin: 70px 0px;
`;
const NewsTitle = styled.div`
    font-size: 1.0rem;
    font-weight: 400;
    letter-spacing: -0.5px;
    margin: 6px 0px;
    line-height: 1.5;
`
const NewsSum = styled.div`
    font-size: 0.9rem;
    color: #767678;
    display: -webkit-box;
    line-height: 1.5;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
`;
const NewsDate = styled.span`
    color: rgba(119, 119, 119, 0.7);
    font-size: 0.8rem;
    letter-spacing: -0.3px;
    font-weight: 400;
    float: right;
    margin-right: 20px;
    margin-top: 5px;
`;
const Box = styled.div`
    height: 80px;
    cursor: default;
`;

const Title = styled.p`
    font-weight: bold;
    margin-bottom: 15px;
`;
